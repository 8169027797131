import React, { useEffect, useState } from "react";
import Question from "./Question";
import { Link } from "react-router-dom";
import ReactHelmet from "./ReactHelmet";
import { useTranslation } from "react-i18next";
import GoogleAdvertise, { displayProps } from "./Adsense";

function Main() {
  const { t } = useTranslation();

  return (
    <>
      <ReactHelmet description={t("mainHelmetDesc")} title={t("mainTitle")} />
      <div id="mainSection" style={{ wordWrap: "break-word" }}>
        <div id="explanation" className="radBox">
          <div className="boxArea col-10 mx-auto">
            <h3 className="mt-4">{t("main")}</h3>
            <h5 className="mt-3">
              <span style={{ color: "#8DB596" }}>{t("serviceIntro1")}</span>
              {t("serviceIntro2")}
            </h5>
            <p>{t("serviceIntro3")}</p>
            <img
              src="https://i.postimg.cc/DyvqDRXC/download.jpg"
              style={{ width: "30%" }}
            />
            <p className="mt-3">{t("introTry")}</p>
            <p className="mt-2">
              ❗ <b>{t("introNotice")}</b> ❗<br />
              <span style={{ fontSize: "0.7rem" }}>{t("introNoticeDesc")}</span>
            </p>
            <p style={{ fontSize: "0.8rem" }}>💡 {t("engSupport")} 💡</p>
          </div>
          <Link to="/questions">
            <button className="submitButton col-11 mx-auto">
              {t("introButton")}
            </button>
          </Link>
        </div>
        {/* <GoogleAdvertise
                            {...displayProps}
                        /> */}
      </div>
    </>
  );
}

export default Main;
