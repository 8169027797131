import {firestore, fstorage, ffieldvalue, auth} from './fbase'

const db = firestore;

const writePost = (uid, nickname, password, contents) =>{
    db.collection('user').doc(uid).get().then((doc) => {
        if (doc.exists) {
            alert('이미 기존에 받은 결과가 있는 것 같아요! 다른 브라우저나 다른 기기에서 다시 시도해주세요.')
            window.location.replace('/')
        } else {
            db.collection('user').doc(uid).set({
                nickName: nickname,
                password: password,
                myAnswer: contents,
                otherAnswers: [],
            })
        }
    })
        .then((docRef) => {
            console.log('Done');
        })
        .catch((error) => {
            console.error('Error adding doc: ', error);
            window.alert('다시 시도해주세요 ㅠㅠ')
        });
}


const updateOtherPost = (uid, contents) => {
    db.collection('user').doc(uid).update({
        otherAnswers: ffieldvalue.arrayUnion(contents)
        });
}

const updateMyPost = (uid, contents) => {
    db.collection('user').doc(uid).update({
        myAnswer: contents
    })
}

const delSection = (uid, contents) => {
    db.collection('user').doc(uid).update({
        otherAnswers: ffieldvalue.arrayRemove(contents)
    })
}

const annonymous = () => {
    auth.signInAnonymously()
        .then(()=>{
            console.log('Signed In')
            auth.onAuthStateChanged((user) => {
                window.localStorage.setItem('UID', user.uid)
            })
        })
        .catch((error)=> {
            console.error(error.message)

        })
}


export {
    writePost,
    updateOtherPost,
    annonymous,
    updateMyPost,
    delSection,
}