import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGIN_ID,
    appId: process.env.REACT_APP_APP_ID
};

firebase.initializeApp(firebaseConfig);
const fbAnalytics = firebase.analytics();

const firestore = firebase.firestore();
const fstorage = firebase.storage()
const ffieldvalue = firebase.firestore.FieldValue

const auth = firebase.auth();
const user = firebase.auth().currentUser;

// 필요한 곳에서 사용할 수 있도록 내보내기
export { firestore, user, auth, fstorage, ffieldvalue, firebaseConfig, fbAnalytics};