const initialData = {
    personalities : {
        'personality-0': { id: 'personality-0', content: '단순한', },
        'personality-1': { id: 'personality-1', content: '개척적인' },
        'personality-2': { id: 'personality-2', content: '결단력 있는' },
        'personality-3': { id: 'personality-3', content: '경쟁적인' },
        'personality-4': { id: 'personality-4', content: '고집이 있는' },
        'personality-5': { id: 'personality-5', content: '공격적인' },
        'personality-6': { id: 'personality-6', content: '단호한' },
        'personality-7': { id: 'personality-7', content: '도전적인' },
        'personality-8': { id: 'personality-8', content: '독립적인' },
        'personality-9': { id: 'personality-9', content: '목표를 이루는' },
        'personality-10': { id: 'personality-10', content: '성취가 중요한' },
        'personality-11': { id: 'personality-11', content: '실행력 있는' },
        'personality-12': { id: 'personality-12', content: '엄격한' },
        'personality-13': { id: 'personality-13', content: '의지가 강한' },
        'personality-14': { id: 'personality-14', content: '논쟁하는' },
        'personality-15': { id: 'personality-15', content: '주도적인' },
        'personality-16': { id: 'personality-16', content: '지도력 있는' },
        'personality-17': { id: 'personality-17', content: '참을성이 부족한' },
        'personality-18': { id: 'personality-18', content: '추진력 있는' },
        'personality-19': { id: 'personality-19', content: '활동적인' },
        'personality-20': { id: 'personality-20', content: '객관적인' },
        'personality-21': { id: 'personality-21', content: '계획적인' },
        'personality-22': { id: 'personality-22', content: '관례를 따르는' },
        'personality-23': { id: 'personality-23', content: '근거가 중요한' },
        'personality-24': { id: 'personality-24', content: '꼼꼼한' },
        'personality-25': { id: 'personality-25', content: '끈기 있는' },
        'personality-26': { id: 'personality-26', content: '논리적인' },
        'personality-27': { id: 'personality-27', content: '단계적인' },
        'personality-28': { id: 'personality-28', content: '분석적인' },
        'personality-29': { id: 'personality-29', content: '비판적인' },
        'personality-30': { id: 'personality-30', content: '시간이 걸리는' },
        'personality-31': { id: 'personality-31', content: '신중한' },
        'personality-32': { id: 'personality-32', content: '완벽을 추구하는' },
        'personality-33': { id: 'personality-33', content: '일관성 있는' },
        'personality-34': { id: 'personality-34', content: '정확한' },
        'personality-35': { id: 'personality-35', content: '조직화하는' },
        'personality-36': { id: 'personality-36', content: '체계적인' },
        'personality-37': { id: 'personality-37', content: '침착한' },
        'personality-38': { id: 'personality-38', content: '한 가지에 집중하는' },
        'personality-39': { id: 'personality-39', content: '현실감 있는' },
        'personality-40': { id: 'personality-40', content: '감성적인' },
        'personality-41': { id: 'personality-41', content: '낙관적인' },
        'personality-42': { id: 'personality-42', content: '대인관계가 넓은' },
        'personality-43': { id: 'personality-43', content: '언변이 뛰어난' },
        'personality-44': { id: 'personality-44', content: '모임을 즐기는' },
        'personality-45': { id: 'personality-45', content: '변덕스러운' },
        'personality-46': { id: 'personality-46', content: '변화를 즐기는' },
        'personality-47': { id: 'personality-47', content: '사교적인' },
        'personality-48': { id: 'personality-48', content: '생기발랄한' },
        'personality-49': { id: 'personality-49', content: '설득력 있는' },
        'personality-50': { id: 'personality-50', content: '쉽게 싫증내는' },
        'personality-51': { id: 'personality-51', content: '에너지 있는' },
        'personality-52': { id: 'personality-52', content: '열정적인' },
        'personality-53': { id: 'personality-53', content: '유머감각 있는' },
        'personality-54': { id: 'personality-54', content: '융통성 있는' },
        'personality-55': { id: 'personality-55', content: '인기 있는' },
        'personality-56': { id: 'personality-56', content: '적응력이 뛰어난' },
        'personality-57': { id: 'personality-57', content: '즐거움을 찾는' },
        'personality-58': { id: 'personality-58', content: '충동적인' },
        'personality-59': { id: 'personality-59', content: '타인에 민감한' },
        'personality-60': { id: 'personality-60', content: '이해가 빠른' },
        'personality-61': { id: 'personality-61', content: '기획하는' },
        'personality-62': { id: 'personality-62', content: '동기유발하는' },
        'personality-63': { id: 'personality-63', content: '디테일에 약한' },
        'personality-64': { id: 'personality-64', content: '마무리에 약한' },
        'personality-65': { id: 'personality-65', content: '문제를 해결하는' },
        'personality-66': { id: 'personality-66', content: '미래지향적인' },
        'personality-67': { id: 'personality-67', content: '사색하는' },
        'personality-68': { id: 'personality-68', content: '전략적인' },
        'personality-69': { id: 'personality-69', content: '생각이 빠른' },
        'personality-70': { id: 'personality-70', content: '소신있는' },
        'personality-71': { id: 'personality-71', content: '아이디어가 많은' },
        'personality-72': { id: 'personality-72', content: '원리를 파악하는' },
        'personality-73': { id: 'personality-73', content: '이성적인' },
        'personality-74': { id: 'personality-74', content: '창의적인' },
        'personality-75': { id: 'personality-75', content: '추상적인' },
        'personality-76': { id: 'personality-76', content: '큰 그림을 보는' },
        'personality-77': { id: 'personality-77', content: '통찰력 있는' },
        'personality-78': { id: 'personality-78', content: '혁신적인' },
        'personality-79': { id: 'personality-79', content: '호기심 많은' },
        'personality-80': { id: 'personality-80', content: '겸손한' },
        'personality-81': { id: 'personality-81', content: '경청하는' },
        'personality-82': { id: 'personality-82', content: '느긋한' },
        'personality-83': { id: 'personality-83', content: '대인관계가 원만한' },
        'personality-84': { id: 'personality-84', content: '동정심이 많은' },
        'personality-85': { id: 'personality-85', content: '불확실이 두려운' },
        'personality-86': { id: 'personality-86', content: '성실한' },
        'personality-87': { id: 'personality-87', content: '수동적인' },
        'personality-88': { id: 'personality-88', content: '수용적인' },
        'personality-89': { id: 'personality-89', content: '안정적인' },
        'personality-90': { id: 'personality-90', content: '온화한' },
        'personality-91': { id: 'personality-91', content: '욕심이 적은' },
        'personality-92': { id: 'personality-92', content: '우유부단한' },
        'personality-93': { id: 'personality-93', content: '이해심 많은' },
        'personality-94': { id: 'personality-94', content: '인내심 있는' },
        'personality-95': { id: 'personality-95', content: '인심 좋은' },
        'personality-96': { id: 'personality-96', content: '절제력 있는' },
        'personality-97': { id: 'personality-97', content: '팀웍이 좋은' },
        'personality-98': { id: 'personality-98', content: '현재에 충실한' },
        'personality-99': { id: 'personality-99', content: '협조적인' },
    },
    columns: {

        'column-2': {
            id: 'column-2',
            title: '🧐❓',
            personalityIds: ['personality-0', 'personality-1', 'personality-2', 'personality-3', 'personality-4', 'personality-5',
            'personality-6', 'personality-7', 'personality-8', 'personality-9', 'personality-10', 'personality-11', 'personality-12', 'personality-13', 'personality-14', 'personality-15',
            'personality-16', 'personality-17', 'personality-18', 'personality-19', 'personality-20', 'personality-21', 'personality-22', 'personality-23', 'personality-24', 'personality-25',
            'personality-26', 'personality-27', 'personality-28', 'personality-29', 'personality-30', 'personality-31', 'personality-32', 'personality-33', 'personality-34', 'personality-35',
            'personality-36', 'personality-37', 'personality-38', 'personality-39', 'personality-40', 'personality-41', 'personality-42', 'personality-43', 'personality-44', 'personality-45',
            'personality-46', 'personality-47', 'personality-48', 'personality-49', 'personality-50', 'personality-51', 'personality-52', 'personality-53', 'personality-54', 'personality-55',
            'personality-56', 'personality-57', 'personality-58', 'personality-59', 'personality-60', 'personality-61', 'personality-62', 'personality-63', 'personality-64', 'personality-65',
            'personality-66', 'personality-67', 'personality-68', 'personality-69', 'personality-70', 'personality-71', 'personality-72', 'personality-73', 'personality-74', 'personality-75',
            'personality-76', 'personality-77', 'personality-78', 'personality-79', 'personality-80', 'personality-81', 'personality-82', 'personality-83', 'personality-84', 'personality-85',
            'personality-86', 'personality-87', 'personality-88', 'personality-89', 'personality-90', 'personality-91', 'personality-92', 'personality-93', 'personality-94', 'personality-95',
            'personality-96', 'personality-97', 'personality-98', 'personality-99'],
        },
        'column-3': {
            id: 'column-3',
            title: '🙆‍♀🙆‍♂️',
            personalityIds: [],
        },
    },
    columnOrder: ['column-2', 'column-3']
};

export default initialData;