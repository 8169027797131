import React from 'react';
import styled from 'styled-components';
import {Draggable} from "react-beautiful-dnd";
import {useTranslation} from "react-i18next";


const Container = styled.div`
    background-color: ${props => (`#${colorArray[props.persId.split('-')[1]%10]}`)};
`

const colorArray = ['FAEBE0', 'CEE5D0', 'E7D4B5', 'E3CDC1', 'E6C4C0', 'F8F5F1', 'C2B092', 'D7D1C9', 'EADCA6', 'C3AED6']


export default function Task(props) {
    const { t } = useTranslation()
    return(
        <Draggable key={props.personality.id} draggableId={props.personality.id} index={props.index}>
            {(provided, snapshot) => (
                <Container
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    isDragging={snapshot.isDragging}
                    persId={props.personality.id}
                    className='questionStacks'
                >
                    {t(`p${props.personality.id.split('-')[1]}`)}
                </Container>
            )
            }
        </Draggable>
    )
}