import "./questions.css";
import { BrowserRouter, Route, Link, Switch } from "react-router-dom";
import Main from "./Main";
import React, { Suspense, lazy } from "react";
import {
  // Adfit,
  // Adfits,
  Footer,
  Header,
  WholeDiv,
  // Coupang,
  // CoupangDesc,
} from "./components/Styles";
// import adfitChild from "./components/AdfitChild";
// import { QuestionProvider } from "./QuestionContext";
import { useTranslation } from "react-i18next";
import Toss from "./img/toss.png";

const Question = lazy(() => import("./Question"));
const Result = lazy(() => import("./Result"));
const NotFound = lazy(() => import("./NotFound"));
// const Blog = lazy(() => import("./components/blog/BlogList"));
// const BlogContent = lazy(() => import("./components/blog/BlogContent"));
const Privacy = lazy(() => import("./Privacy"));

const App = () => {
  const { t } = useTranslation();

  return (
    <>
      <WholeDiv>
        <BrowserRouter>
          <Header>
            <Link to="/" style={{ textDecoration: "none", color: "#A98B98" }}>
              <h1>{t("mainTitle")}</h1>
            </Link>
          </Header>
          <Suspense fallback={<div>{t("loading")} 🤍</div>}>
            <center>
              <Switch>
                <Route path="/" exact component={Main} />
                <Route path="/questions" exact component={Question} />
                <Route path="/questions/:uid" exact component={Question} />
                <Route path="/result/:uid" exact component={Result} />
                <Route path="/404" exact component={NotFound} />
                {/* <Route path="/blog" exact component={Blog} /> */}
                {/* <Route path="/blog/:id" component={BlogContent} /> */}
                <Route path="/privacy" component={Privacy} />
              </Switch>
            </center>
          </Suspense>
        </BrowserRouter>
      </WholeDiv>
      <Footer>
        <div style={{ flexGrow: 1, textAlign: "right" }}>
          Yj all rights reserved. <br />
          {t("bugReport")}: my.personality.check@gmail.com <br />
          <a
            href={
              "https://silky-trout-731.notion.site/2c0e9a06f4d349988cfccdbfb4d1d46f"
            }
            target="_blank"
            style={{ color: "#5089C6", textDecoration: "none" }}
          >
            FAQ
          </a>
        </div>
        <div style={{ flexGrow: 1 }}>
          <a href="https://toss.me/jujuu" target="_blank">
            <img
              height="40"
              style={{ border: 0, height: "40px", width: "200px" }}
              src={Toss}
              border="0"
              alt="thank you for button"
            />
          </a>
        </div>
      </Footer>
    </>
  );
};

export default App;
