import React, { useEffect, useState, useRef } from "react";
import { Form } from "react-bootstrap";
import { annonymous, updateMyPost, updateOtherPost, writePost } from "./Api";
import { Link, useParams } from "react-router-dom";
import ReactHelmet from "./ReactHelmet";
import Caution from "./components/question/Caution";
import styled from "styled-components";
import { DragDropContext } from "react-beautiful-dnd";
import initialData from "./OriginalData";
import Column from "./column";
import { StyledButton } from "./components/Styles";
import { useTranslation } from "react-i18next";
import queryString from "query-string";

export default function Question({ location, match }) {
  const { uid } = useParams();
  const inputRelationship = useRef();
  const inputNickname = useRef();
  const inputPassword = useRef();
  const page = match.url.split("/")[1];
  const { t } = useTranslation();

  const query = location ? queryString.parse(location.search) : "";
  const { rel } = query;

  // Dnd
  const [state, setState] = useState({
    ...initialData,
    personalityIds: shuffle(initialData.columns["column-2"].personalityIds),
  });

  function shuffle(array) {
    array.sort(() => Math.random() - 0.5);
  }

  function onDragEnd(result) {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const start = state.columns[source.droppableId];
    const finish = state.columns[destination.droppableId];

    if (start == finish) {
      const newPersonalityIds = Array.from(start.personalityIds);
      newPersonalityIds.splice(source.index, 1);
      newPersonalityIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        personalityIds: newPersonalityIds,
      };

      const newState = {
        ...state,
        columns: {
          ...state.columns,
          [newColumn.id]: newColumn,
        },
      };
      setState(newState);
      return;
    }

    const startPersonalityIds = Array.from(start.personalityIds);
    startPersonalityIds.splice(source.index, 1);
    const newStart = {
      ...start,
      personalityIds: startPersonalityIds,
    };

    const finishPersonalityIds = Array.from(finish.personalityIds);
    finishPersonalityIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      personalityIds: finishPersonalityIds,
    };

    const newState = {
      ...state,
      columns: {
        ...state.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    };
    setState(newState);
    return;
  }

  const pageShift = (fir, sec) => {
    const a = document.querySelector(`#${fir}`);
    const b = document.querySelector(`#${sec}`);
    a.style.WebKitAnimation = "fadeout 1s";
    a.style.animation = "fadeout 1s";
    setTimeout(() => {
      b.style.WebKitAnimation = "fadein 1s";
      b.style.animation = "fadein 1s";
      setTimeout(() => {
        a.style.display = "none";
        b.style.display = "block";
      }, 450);
    }, 450);
  };

  const onButtonNext1 = () => {
    pageShift("explanation", "questionnaire");
  };

  const onButtonNext2 = () => {
    const persLen = state.columns["column-3"].personalityIds.length;
    const answerSet = [];
    state.columns["column-3"].personalityIds.map((pers) =>
      answerSet.push(pers.split("-")[1])
    );
    if (page === "result") {
      if (persLen >= 10 && persLen <= 20) {
        pageShift("questionnaire", "theEnd");
        updateMyPost(uid, answerSet);
      } else {
        window.alert(t("alert2"));
      }
    } else {
      if (uid) {
        if (persLen === 10) {
          pageShift("questionnaire", "infoArea");
          annonymous();
        } else {
          window.alert(t("alert1"));
        }
      } else {
        if (persLen >= 10 && persLen <= 20) {
          pageShift("questionnaire", "infoArea");
          annonymous();
        } else {
          window.alert(t("alert2"));
        }
      }
    }
  };

  const sendInfo = (e) => {
    e.preventDefault();
    const answerSet = [];

    state.columns["column-3"].personalityIds.map((pers) =>
      answerSet.push(pers.split("-")[1])
    );
    if (uid) {
      const obj = {};
      obj["relationship"] = inputRelationship.current.value;
      obj["nickName"] = inputNickname.current.value;
      obj["answer"] = answerSet;
      updateOtherPost(uid, obj);
    } else {
      const uuid = window.localStorage.getItem("UID");
      writePost(
        uuid,
        inputNickname.current.value,
        inputPassword.current.value,
        answerSet
      );
    }
    pageShift("infoArea", "moveOn");
  };

  const toResult = () => {
    const uuid = window.localStorage.getItem("UID");
    window.location.replace(`/result/${uuid}`);
  };

  return (
    <>
      <ReactHelmet
        description={t("questionHelmetDesc")}
        title={t("questionHelmetTitle")}
      />
      <QuestionDiv style={{ wordWrap: "break-word" }}>
        <div id="explanation" className="radBox">
          <div className="boxArea col-10 mx-auto">
            <Caution page={page} whom={uid} />
            <span>
              <a
                href={`/result/${uid}`}
                style={{ color: "#5089C6", fontSize: "0.7rem" }}
              >
                {uid && "▲"}
              </a>
            </span>
          </div>
          <StyledButton onClick={onButtonNext1}>{t("buttonNext")}</StyledButton>
        </div>
        <div id="questionnaire" className="radBox">
          <DragDropContext onDragEnd={onDragEnd}>
            <div
              className="boxArea col-12 mx-auto"
              style={{ flexDirection: "row" }}
            >
              {state.columnOrder.map((columnId) => {
                const column = state.columns[columnId];
                const personalities = column.personalityIds.map(
                  (personalityId) => state.personalities[personalityId]
                );
                return (
                  <Column
                    key={column.id}
                    column={column}
                    personalities={personalities}
                  />
                );
              })}
            </div>
          </DragDropContext>
          <p style={{ fontSize: "0.7rem", margin: "0", color: "dimgrey" }}>
            {t("mobileNotice")}
          </p>
          <StyledButton onClick={onButtonNext2}>{t("buttonNext")}</StyledButton>
        </div>
        <Form id="infoArea" className="radBox" onSubmit={sendInfo}>
          <div className="boxArea col-11 mx-auto" style={{ textAlign: "left" }}>
            {!uid && (
              <>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>{t("pwInput")}</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    pattern="[0-9]{4}"
                    ref={inputPassword}
                    placeholder={t("pwInputPlaceholder")}
                  />
                  <Form.Text className="text-muted">
                    {t("pwInputDesc1")} <br />
                    {t("pwInputDesc2")}
                  </Form.Text>
                </Form.Group>
                <br />
              </>
            )}
            <Form.Group className="mb-3" controlId="formBasicNickname">
              <Form.Label>{t("nickInput")}</Form.Label>
              <Form.Control
                required
                type="text"
                ref={inputNickname}
                placeholder={t("nickInputPlaceholder")}
              />
              <Form.Text className="text-muted">
                {t("nickInputDesc1")} {uid ? t("toReq") : t("toRelations")}
                {t("nickInputDesc2")} <br />
                {t("nickInputDesc3")}
              </Form.Text>
            </Form.Group>
            {uid && (
              <>
                <br />
                <Form.Label>{t("relInput")}</Form.Label>
                <Form.Control
                  required
                  as="select"
                  ref={inputRelationship}
                  disabled={rel}
                >
                  <option value="family" selected={rel == 0}>
                    {t("family")}
                  </option>
                  <option value="friend" selected={rel == 1}>
                    {t("friend")}
                  </option>
                  <option value="lover" selected={rel == 2}>
                    {t("lover")}
                  </option>
                  <option value="acquaintance" selected={rel == 3}>
                    {t("acquaintance")}
                  </option>
                </Form.Control>
              </>
            )}
          </div>
          <StyledButton type="submit">💌 {t("submit")} 💌</StyledButton>
        </Form>
        <div id="moveOn" className="radBox" style={{ wordWrap: "break-word" }}>
          <div className="boxArea col-10 mx-auto">
            <h4>
              {uid ? (
                t("wannaKnowMe")
              ) : (
                <span style={{ color: "#9B72AA" }}>{t("shareWhoKnowMe")}</span>
              )}
            </h4>
            <p></p>
            {uid ? (
              <p>{t("wannaKnowMeDesc")}</p>
            ) : (
              <>
                <p>{t("shareWhoKnowMeDesc1")}</p>
                <p>{t("shareWhoKnowMeDesc2")}</p>
                <p style={{ color: "#DF5E5E" }}>{t("shareNotice")}</p>
              </>
            )}
          </div>
          {uid ? (
            <Link to="/">
              <button className="submitButton col-11 mx-auto">
                {t("makeMine")}✈
              </button>
            </Link>
          ) : (
            <StyledButton onClick={toResult}>{t("toResult")}✈</StyledButton>
          )}
        </div>
        <div id="theEnd" className="radBox">
          <div className="boxArea col-10 mx-auto">
            <p>{t("againSentence")}</p>
            <p>{t("againDesc")}</p>
          </div>
        </div>
      </QuestionDiv>
    </>
  );
}

const QuestionDiv = styled.div`
  text-align: center;
  max-width: 890px;
  justify-content: center;
  margin: 0 auto;
`;
