import React from 'react';
import { Helmet } from 'react-helmet';


const ReactHelmet = ({ description, title }) => {
    return (
        <Helmet>
            <meta name="description" content={description} />
            <title>{title}</title>
            <meta property="og:title" content={title} />
            <meta property="og:site_name" content="내가 보는 나, 남이 보는 나" />
            <meta property="og:description" content={description} />

            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:image" content='https://i.postimg.cc/SR3YJ1g9/download-2.jpg' />
        </Helmet>
    );
};
export default ReactHelmet;
