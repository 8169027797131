import React from 'react';
import Personality from './personality';
import {Droppable} from "react-beautiful-dnd";
import styled from 'styled-components';

const PersonalityList = styled.div`
    background-color: ${props => props.isDraggingOver ? '#FAF3F3' : '#F9F9F9'};
    transition: background-color 0.6s ease;
    border-radius: 15px;
    min-height: 50vh;
    `;


class InnerList extends React.Component {
    shouldComponentUpdate(nextProps) {
        if (nextProps.personalities === this.props.personalities) {
            return false;
        }
        return true
    }
    render () {
        return this.props.personalities.map((personality, index) => (
            <Personality key={personality.id} personality={personality} index={index} />
        ))
    }
}

export default class Column extends React.Component {
    render() {
        return (
            <div id='questionSection' className='pt-2 mx-2 mt-2 col-5'>
                <h4>{this.props.column.title}</h4>
                <p style={{fontSize:'0.5rem'}}>({this.props.column.personalityIds.length})</p>
                <Droppable droppableId={this.props.column.id} key={this.props.column.id}>
                    {(provided, snapshot) => (
                        <PersonalityList
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            isDraggingOver={snapshot.isDraggingOver}
                            className='innerBox'
                            style={{overflow:'auto'}}
                        >
                            <InnerList personalities={this.props.personalities}/>
                            {provided.placeholder}
                        </PersonalityList>
                    )}
                </Droppable>
            </div>


    )
    }
}