import React from 'react';
import styled from 'styled-components';

// App.js

export const WholeDiv = styled.div`
  display: flex;
  flex-direction: column;
  font-family: GmarketSansMedium;
  justify-content: center;
  word-break: keep-all;
  margin: 0 auto;
  text-align: center;
`;

export const Header = styled.div`
  text-align: center;
  margin-top: 2vh;
`;

export const Adfit = styled.div`
  max-width: 780px;
  margin: 10px auto;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
`;

export const Adfits = styled.div`
   & + & {
     margin-left: 15px;
   }
`;

export const Footer = styled.div`
  margin: 15px auto;
  display: flex;
  max-width: 780px;
  font-size: 0.5rem;
  color: lightgrey;
  text-decoration: none;
`;

export const StyledButton = styled.button`
  padding: 0;
  border: 3px solid #A98B98;
  border-radius: 12px;
  background-color: #A98B98;
  opacity: 80%;
  color: white;
  margin: ${props=> props.size ? '0': '0 auto 10px'};
  width: ${props => props.size || '90%'};
  height: 50px;
`;

export const Coupang = styled.div`
  width: 380px;
  justify-content: center;
  text-align: center;
`;

export const CoupangDesc = styled.div`
  color: dimgrey;
  font-size: 0.5rem;
  text-align: center;
  margin-bottom: 0.5rem;
`;