import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import translationEn from "./translation.en.json";
import translationKo from "./translation.ko.json";
import translationJp from "./translation.jp.json";

const detectionOptions = {
  order: ["cookie", "navigator"],
  lookupFromPathIndex: 0,
};

const resource = {
  en: {
    translation: translationEn,
  },
  ko: {
    translation: translationKo,
  },
  ja: {
    translation: translationJp,
  },
  zh: {
    // TODO: 중국어
  },
  es: {
    // TODO: 스페인어
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      backends: [HttpApi],
    },
    resources: resource,
    debug: "true",
    fallbackLng: "en",
    detection: detectionOptions,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
