import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";

export default function Caution ( { page, whom } ) {
    const [toWhom, setToWhom] = useState('');
    const [howMany, setHowMany] = useState('');
    const { t } = useTranslation()

    useEffect(()=> {
        if (page==='result' || (page!=='result'&&whom===undefined)) {
            setToWhom(t('questionMe'));
            setHowMany(t('tenPlus'))
        } else {
            setToWhom(t('questionOther'));
            setHowMany(t('onlyTen'))
        }
    }, [])



    return (
        <>
            <RedCaution>{t('question0')}{toWhom}{t('questionDesc0')}</RedCaution>
            <p> {t('questionDesc1')} <br/> <Highlighting>{toWhom} </Highlighting>{t('questionDesc2')}</p>
            <p>{t('hundred')} <Highlighting>{howMany}</Highlighting> {t('questionsLimit')}</p>

            <p>{t('wordsNoMeaning')}</p>
        </>
    )
}

const Highlighting = styled.span`
  color: #9B72AA;
  font-size: 1.15rem;
  font-weight: bold;
`;

const RedCaution = styled.span`
    color: #FF7878;
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
`;
